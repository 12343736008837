import { SVGProps } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { CommonButton } from 'components/common-button'

interface SidebarButtonProps {
  href?: string
  onClick?: () => void | Promise<void>
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  isLoading?: boolean
  isCurrentlyVisited?: boolean
  className?: string
}

export const SidebarButton = ({
  href,
  icon: Icon,
  onClick,
  isLoading = false,
  isCurrentlyVisited = false,
  className = '',
}: SidebarButtonProps) => {
  if (href) {
    return (
      <Link href={href}>
        <div
          className={clsx(
            'inline-flex items-center justify-center gap-2 rounded-full p-3 text-sm font-medium text-white-transparent60 ring-offset-background transition-colors hover:bg-white-transparent15 hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
            isCurrentlyVisited && 'bg-white-transparent15 !text-white',
            className,
          )}
        >
          <Icon className="inline-block h-5 w-5 items-center justify-center" />
        </div>
      </Link>
    )
  }

  return (
    <CommonButton
      iconOnly
      icon={Icon}
      variant="ghost"
      size="icon-xl"
      className={clsx(
        'bg-transparent text-white-transparent60 hover:bg-white-transparent15 hover:text-white active:bg-white-transparent15 active:text-white',
        isCurrentlyVisited && 'bg-white-transparent15 text-white',
        className,
      )}
      onClick={onClick}
      isLoading={isLoading}
    />
  )
}
