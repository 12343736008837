import { SVGProps } from 'react'

export const DeployedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.16667 15.1875V9.47916L2.16667 6.58332V12.2917L7.16667 15.1875ZM8.83333 15.1875L13.8333 12.2917V6.58332L8.83333 9.47916V15.1875ZM8 8.04166L12.9375 5.18749L8 2.33332L3.0625 5.18749L8 8.04166ZM0.5 13.2708V4.72916L8 0.416656L15.5 4.72916V13.2708L8 17.5833L0.5 13.2708Z"
      fill="currentColor"
    />
  </svg>
)
