import { useMyAvailableInvitesCountQuery } from 'apollo/generated/graphqlClient'

export const useAvailableInvitesCount = () => {
  const { data, loading, error } = useMyAvailableInvitesCountQuery()

  return {
    data: data?.myAvailableInvitesCount,
    isLoading: loading,
    error,
  }
}
