import { cn } from 'lib/utils'
import { Button, ButtonProps } from 'components/ui/button'

interface CommonButtonProps extends ButtonProps {
  colorVariant?: 'primary' | 'secondary'
}

export const CommonButton = ({
  children,
  className,
  colorVariant = 'primary',
  ...props
}: CommonButtonProps) => (
  <Button
    className={cn(
      'w-fit rounded-full',
      colorVariant === 'primary'
        ? 'bg-primary-transparent60 text-white hover:bg-primary-transparent85 active:bg-primary-transparent90 disabled:bg-primary-transparent60'
        : 'bg-white-transparent10 hover:bg-white-transparent25 active:bg-white-transparent25',
      className,
    )}
    {...props}
  >
    {children}
  </Button>
)
