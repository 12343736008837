import { ReactElement } from 'react'
import { useMe } from 'hooks/useMe'
import { useTranslation } from 'next-i18next'
import CopyLinkBox from 'components/copy-link-box/copy-link-box'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'
import InvitesLeftIndicator from './invites-left-indicator'

interface SettingsDialogToggleProps {
  toggle: ReactElement
  invitesCount: number
}

export const InvitationDialogToggle = ({ toggle, invitesCount }: SettingsDialogToggleProps) => {
  const { t } = useTranslation('common')
  const { me } = useMe()

  const inviteLink = `${process.env.NEXT_PUBLIC_WEB_URL}/invite/${me?.userName}`

  return (
    <Dialog>
      <DialogTrigger>{toggle}</DialogTrigger>
      <DialogContent className="w-fit bg-gray-transparent70 p-10 font-madefor backdrop-blur-sm">
        <DialogHeader>
          <DialogTitle className="font-light text-white">
            {t('components.invitation.title')}
          </DialogTitle>
        </DialogHeader>
        <div className="mt-[-8px] flex max-w-[300px] flex-col gap-5 font-light text-white">
          <div>
            <p className="text-sm text-white-transparent60">
              {t('components.invitation.description')}
            </p>
          </div>
          <CopyLinkBox link={inviteLink} />
          <InvitesLeftIndicator invitesLeft={invitesCount} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
