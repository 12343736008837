import { IconProps } from './types'

export const ChevronDownFilled = (props: IconProps) => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.56234 4.06266L0.541504 1.04183C0.499837 1.00016 0.468726 0.955163 0.448171 0.906829C0.427615 0.858496 0.417059 0.806274 0.416504 0.750163C0.416504 0.639052 0.454837 0.541829 0.531504 0.458496C0.608171 0.375162 0.708726 0.333496 0.833171 0.333496H7.1665C7.2915 0.333496 7.39234 0.375162 7.469 0.458496C7.54567 0.541829 7.58373 0.639052 7.58317 0.750163C7.58317 0.777941 7.5415 0.875163 7.45817 1.04183L4.43734 4.06266C4.36789 4.13211 4.29845 4.18072 4.229 4.2085C4.15956 4.23627 4.08317 4.25016 3.99984 4.25016C3.9165 4.25016 3.84012 4.23627 3.77067 4.2085C3.70123 4.18072 3.63178 4.13211 3.56234 4.06266Z"
      fill="currentColor"
    />
  </svg>
)
