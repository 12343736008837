import { SVGProps } from 'react'

export const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.16667 15.5C1.70833 15.5 1.31611 15.3369 0.99 15.0108C0.663889 14.6847 0.500556 14.2922 0.5 13.8333V2.16667C0.5 1.70833 0.663333 1.31611 0.99 0.99C1.31667 0.663889 1.70889 0.500556 2.16667 0.5H7.16667C7.40278 0.5 7.60083 0.58 7.76083 0.74C7.92083 0.9 8.00056 1.09778 8 1.33333C7.99944 1.56889 7.91944 1.76694 7.76 1.9275C7.60056 2.08806 7.40278 2.16778 7.16667 2.16667H2.16667V13.8333H7.16667C7.40278 13.8333 7.60083 13.9133 7.76083 14.0733C7.92083 14.2333 8.00056 14.4311 8 14.6667C7.99944 14.9022 7.91944 15.1003 7.76 15.2608C7.60056 15.4214 7.40278 15.5011 7.16667 15.5H2.16667ZM12.3125 8.83333H6.33333C6.09722 8.83333 5.89944 8.75333 5.74 8.59333C5.58056 8.43333 5.50056 8.23556 5.5 8C5.49944 7.76444 5.57944 7.56667 5.74 7.40667C5.90056 7.24667 6.09833 7.16667 6.33333 7.16667H12.3125L10.75 5.60417C10.5972 5.45139 10.5208 5.26389 10.5208 5.04167C10.5208 4.81944 10.5972 4.625 10.75 4.45833C10.9028 4.29167 11.0972 4.20472 11.3333 4.1975C11.5694 4.19028 11.7708 4.27028 11.9375 4.4375L14.9167 7.41667C15.0833 7.58333 15.1667 7.77778 15.1667 8C15.1667 8.22222 15.0833 8.41667 14.9167 8.58333L11.9375 11.5625C11.7708 11.7292 11.5731 11.8092 11.3442 11.8025C11.1153 11.7958 10.9172 11.7089 10.75 11.5417C10.5972 11.375 10.5244 11.1772 10.5317 10.9483C10.5389 10.7194 10.6186 10.5283 10.7708 10.375L12.3125 8.83333Z"
      fill="currentColor"
    />
  </svg>
)
