import { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { Route } from 'constants/common/routes'
import { AuthenticatedLayout } from './AuthenticatedLayout'
import { UnauthenticatedLayout } from './UnauthenticatedLayout'

interface CommonLayoutLayoutProps extends PropsWithChildren {}

const RoutesWithoutLayout: string[] = [Route.SignIn, Route.SignUp]

const getLayout = ({ isLoggedIn, pathname }: { isLoggedIn: boolean; pathname: string }) => {
  if (RoutesWithoutLayout.includes(pathname)) {
    return null
  }
  if (pathname === (Route.SetUpProfile as string)) {
    return UnauthenticatedLayout
  }
  if (isLoggedIn) {
    return AuthenticatedLayout
  }
  return null
}

export const CommonLayout = ({ children }: CommonLayoutLayoutProps) => {
  const { status } = useSession()
  const { pathname } = useRouter()

  const Layout = getLayout({
    isLoggedIn: status === 'authenticated',
    pathname,
  })

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-layout-gradient">
      {Layout && <Layout />}
      {children}
    </div>
  )
}
