import { useState } from 'react'
import { useAvailableInvitesCount } from 'hooks/useAvailableInvitesCount'
import { useMe } from 'hooks/useMe'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { NEXTAUTH_COGNITO_GOOGLE_LOGOUT_URL } from 'constants/common/auth'
import { Route } from 'constants/common/routes'
import { DeployedIcon, LogoutIcon, SettingsIcon, SupportIcon, TicketIcon } from 'components/icons'
import { InvitationDialogToggle } from 'components/invitation-dialog-toggle'
import { InvitationSidebarButton } from 'components/invitation-dialog-toggle/invitation-sidebar-button'
import { LoadingOverlay } from 'components/loading-overlay/loading-overlay'
import { Logo } from 'components/logo'
import { SettingsDialogToggle } from 'components/settings-dialog-toggle'
import { Sidebar, SidebarButton, SidebarButtonGroup, SidebarSection } from 'components/sidebar'
import { UserAvatar } from 'components/userAvatar'

export const AuthenticatedLayout = () => {
  const router = useRouter()
  const { me, isMeLoading } = useMe()
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const { data: availableInvitesCount, isLoading } = useAvailableInvitesCount()

  if (isMeLoading) {
    return <LoadingOverlay />
  }

  if (!me || !me.isProfileComplete) {
    return null
  }

  return (
    <Sidebar>
      <SidebarSection>
        <Logo />
        <SidebarButtonGroup>
          <SidebarButton
            icon={DeployedIcon}
            href={Route.Base}
            isCurrentlyVisited={router.pathname === (Route.Base as string)}
          />
        </SidebarButtonGroup>
      </SidebarSection>
      <SidebarSection>
        <SidebarButtonGroup>
          <SidebarButton
            onClick={async () => {
              try {
                setIsLoggingOut(true)
                await signOut()
                void router.push(NEXTAUTH_COGNITO_GOOGLE_LOGOUT_URL)
              } finally {
                setIsLoggingOut(false)
              }
            }}
            icon={LogoutIcon}
            isLoading={isLoggingOut}
          />
          <SidebarButton icon={SupportIcon} />
          <InvitationDialogToggle
            toggle={
              <InvitationSidebarButton
                icon={TicketIcon}
                notificationsCount={isLoading ? undefined : availableInvitesCount}
              />
            }
            invitesCount={isLoading ? 0 : availableInvitesCount ?? 0}
          />
          <SettingsDialogToggle toggle={<SidebarButton icon={SettingsIcon} />} />
        </SidebarButtonGroup>
        <Link href={Route.ProfileSettings}>
          <UserAvatar src={me.profileImageUrl} name={me.userName as string} />
          {/* @ns: at this moment, userName is surely non null (null is only before set up profile step) */}
        </Link>
      </SidebarSection>
    </Sidebar>
  )
}
