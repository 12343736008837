export enum Route {
  Base = '/',
  SignUp = '/sign-up',
  SignIn = '/sign-in',
  SetUpProfile = '/set-up-profile',
  ProfileSettings = '/profile-settings',
  Error = '/error',
  OpenDesktopApp = '/open-desktop-app',
  Onboarding = '/onboarding',
  Rooms = '/rooms',
}

export const Redirects = {
  SetUpProfile: {
    redirect: {
      destination: Route.SetUpProfile,
      permanent: false,
    },
  },
  Base: {
    redirect: {
      destination: Route.Base,
      permanent: false,
    },
  },
  SignIn: (origin?: string) => ({
    redirect: {
      destination: `${Route.SignIn}${origin && origin !== '/' ? `?origin=${origin}` : ''}`,
      permanent: false,
    },
  }),
  SignInUserNotFound: {
    redirect: {
      destination: `${Route.SignIn}?error=userNotFound`,
      permanent: false,
    },
  },
}
