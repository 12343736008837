import { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { CommonButton } from 'components/common-button'
import { CommonSelect } from 'components/common-select'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'

interface SettingsDialogToggleProps {
  toggle: ReactElement
}

const LanguageOptions = [{ value: 'en', label: 'English' }]

export const SettingsDialogToggle = ({ toggle }: SettingsDialogToggleProps) => {
  const { t, i18n } = useTranslation('common')

  return (
    <Dialog>
      <DialogTrigger>{toggle}</DialogTrigger>
      <DialogContent className="w-[300px] bg-gray-transparent70 p-10 font-madefor backdrop-blur-sm">
        <DialogHeader>
          <DialogTitle className="font-light text-white">
            {t('components.settings.title')}
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-5 font-light text-white">
          <div className="flex flex-col gap-2">
            <p className="text-sm font-semibold">{t('components.settings.languageSelect.label')}</p>
            <CommonSelect
              options={LanguageOptions}
              defaultValue={i18n.language}
              onValueChange={(value) => {
                void i18n.changeLanguage(value)
              }}
              placeholder={t('components.settings.languageSelect.placeholder')}
            />
          </div>
          <div className="flex w-full justify-end gap-1.5">
            <DialogClose>
              <CommonButton colorVariant="secondary">{t('components.settings.close')}</CommonButton>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
