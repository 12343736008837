import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { getInitials } from 'utils/profileImage/getInitials'

interface UserAvatarProps {
  src?: string | null
  name: string
  className?: string
}

export const UserAvatar = ({ src, name, className = '' }: UserAvatarProps) => (
  <Avatar className={className}>
    <AvatarImage src={src ?? undefined} />
    <AvatarFallback>{getInitials(name)}</AvatarFallback>
  </Avatar>
)
