import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from './tooltip'

const TooltipToggle = ({
  trigger,
  tooltipProps,
  triggerProps,
  contentProps,
  children,
}: React.PropsWithChildren<{
  trigger: React.ReactElement
  tooltipProps?: React.ComponentProps<typeof Tooltip>
  triggerProps?: React.ComponentProps<typeof TooltipTrigger>
  contentProps?: React.ComponentProps<typeof TooltipContent>
}>) => (
  <Tooltip {...tooltipProps}>
    <TooltipTrigger {...triggerProps}>{trigger}</TooltipTrigger>
    <TooltipContent sideOffset={6} {...contentProps}>
      {children}
      <TooltipArrow />
    </TooltipContent>
  </Tooltip>
)

export default TooltipToggle
