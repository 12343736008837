import { cn } from 'lib/utils'
import { useTranslation } from 'next-i18next'
import { TicketIcon } from 'components/icons'

interface InvitesLeftIndicatorProps {
  invitesLeft: number
}

const InvitesLeftIndicator = ({ invitesLeft }: InvitesLeftIndicatorProps) => {
  const { t } = useTranslation('common')
  const areSomeInvitesLeft = invitesLeft !== 0

  return (
    <div
      className={cn(
        'flex max-w-fit items-center gap-1 rounded-xl px-2 py-1',
        areSomeInvitesLeft
          ? 'bg-primary-transparent15 text-primary'
          : 'bg-danger-transparent15 text-danger',
      )}
    >
      <TicketIcon className="inline-block h-3 w-3 items-center justify-center" />
      <span className="text-2xs">
        {t('components.invitation.invitesLeft').toString() + invitesLeft}
      </span>
    </div>
  )
}

export default InvitesLeftIndicator
