import {
  Select,
  SelectContent,
  SelectItem,
  SelectProps,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

interface CommonSelectProps extends SelectProps {
  options: { value: string; label: string }[]
  placeholder: string
}

export const CommonSelect = ({ options, placeholder, ...props }: CommonSelectProps) => (
  <Select {...props}>
    <SelectTrigger className="rounded-full border-none bg-secondary-transparent30 px-5 text-sm font-light text-white-transparent60 focus:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:text-white-transparent60">
      <SelectValue placeholder={placeholder} />
    </SelectTrigger>
    <SelectContent className="w-[var(--radix-select-trigger-width)] rounded-[16px] border-none bg-gray-transparent60 p-2.5 backdrop-blur-sm">
      {options.map(({ value, label }) => (
        <SelectItem
          value={value}
          className="my-1 w-[calc(100%-20px)] cursor-pointer rounded-[8px] bg-transparent p-2.5 text-white transition-colors hover:bg-white-transparent10 hover:text-white data-[state=checked]:bg-white-transparent10 data-[state=checked]:text-white"
        >
          {label}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
)
